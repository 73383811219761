<template>
<div class="mt-3 mb-5 py-5 border-bottom">
    <b-row no-gutters class="text-center h3 d-flex align-items-center">
        <b-col cols="12" md="4">
            <inline-svg class="mr-2" :src="svg('pen-tool.svg')" width="30" height="30" />
            <span>Ritningar</span>
        </b-col>
        <b-col cols="12" md="4">
            <inline-svg class="mr-2" :src="svg('clipboard.svg')" width="30" height="30" /> <span>Kontrollansvar, KA</span>
        </b-col>
        <b-col cols="12" md="4">
            <inline-svg class="mr-2" :src="svg('trending-up.svg')" width="30" height="30" /> <span>Redovisningstjänster</span>
        </b-col>
    </b-row>
</div>
</template>

<script>
export default {
    name: 'ServicesSmall',
    components: {},
    data() {
        return {

        };
    },
    mounted() {

    },
    methods: {
        svg(path) {
            return this.$images.svg(path);
        }
    }
};
</script>
